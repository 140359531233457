html,
body,
main,
.disabled,
.disabled *,
[disabled],
[disabled] * {
  touch-action: none;
}

@font-face {
  font-family: "Source Code Pro";
  font-style: normal;
  font-weight: 300;
  src: url("./fonts/Source+Code+Pro_300_normal.woff");
}
@font-face {
  font-family: "Source Code Pro";
  font-style: normal;
  font-weight: 400;
  src: url("./fonts/Source+Code+Pro_400_normal.woff");
}
@font-face {
  font-family: "Source Code Pro";
  font-style: normal;
  font-weight: 600;
  src: url("./fonts/Source+Code+Pro_600_normal.woff");
}
@font-face {
  font-family: "Source Code Pro";
  font-style: normal;
  font-weight: 700;
  src: url("./fonts/Source+Code+Pro_700_normal.woff");
}
.fg-inherit {
  color: inherit !important;
}

.bg-bg {
  background: #607d8b !important;
  background: var(--q-color-bg) !important;
}

.text-textLight {
  color: rgba(250, 250, 250, 0.8) !important;
  color: var(--q-color-textLight) !important;
}

.bg-textLight {
  background: rgba(250, 250, 250, 0.8) !important;
  background: var(--q-color-textLight) !important;
}

.text-textDark {
  color: rgba(33, 33, 33, 0.8) !important;
  color: var(--q-color-textDark) !important;
}

.bg-textDark {
  background: rgba(33, 33, 33, 0.8) !important;
  background: var(--q-color-textDark) !important;
}

.text-ui {
  color: #263238 !important;
  color: var(--q-color-ui) !important;
}

.bg-ui {
  background: #263238 !important;
  background: var(--q-color-ui) !important;
  color: rgba(33, 33, 33, 0.8) !important;
  color: var(--q-color-textDark) !important;
}
body.isDark .bg-ui {
  color: rgba(250, 250, 250, 0.8) !important;
  color: var(--q-color-textLight) !important;
}

.bg-primary {
  color: rgba(33, 33, 33, 0.8) !important;
  color: var(--q-color-textDark) !important;
}
body.primaryDark .bg-primary {
  color: rgba(250, 250, 250, 0.8) !important;
  color: var(--q-color-textLight) !important;
}

.bg-accent {
  background: #37474f !important;
  background: var(--q-color-accent) !important;
  color: rgba(33, 33, 33, 0.8) !important;
  color: var(--q-color-textDark) !important;
}
body.accentDark .bg-accent {
  color: rgba(250, 250, 250, 0.8) !important;
  color: var(--q-color-textLight) !important;
}

.text-panel {
  color: rgba(120, 144, 156, 0.75) !important;
  color: var(--q-color-panel) !important;
  color: rgba(33, 33, 33, 0.8);
  color: var(--q-color-textDark);
}

.bg-panel,
.bg-panelOpaque {
  background: rgba(120, 144, 156, 0.75) !important;
  background: var(--q-color-panel) !important;
  color: rgba(33, 33, 33, 0.8);
  color: var(--q-color-textDark);
}
body.panelDark .bg-panel,
body.panelDark .bg-panelOpaque {
  color: rgba(250, 250, 250, 0.8);
  color: var(--q-color-textLight);
}

.text-player1 {
  color: #cfd8dc !important;
  color: var(--q-color-player1) !important;
}

.bg-player1 {
  background: #cfd8dc;
  background: var(--q-color-player1) !important;
  color: rgba(33, 33, 33, 0.8);
  color: var(--q-color-textDark);
}
body.player1Dark .bg-player1 {
  color: rgba(250, 250, 250, 0.8);
  color: var(--q-color-textLight);
}

.text-player2 {
  color: #263238;
  color: var(--q-color-player2) !important;
}

.bg-player2 {
  background: #263238;
  background: var(--q-color-player2) !important;
  color: rgba(33, 33, 33, 0.8);
  color: var(--q-color-textDark);
}
body.player2Dark .bg-player2 {
  color: rgba(250, 250, 250, 0.8);
  color: var(--q-color-textLight);
}

.q-dark {
  background: #37474f;
  background: var(--q-color-accent);
}

.q-card,
.q-card--dark,
.q-list,
.q-list--dark,
.q-tab-panels,
.q-tab-panels--dark {
  background: #263238;
  background: var(--q-color-ui);
}

::selection {
  background: #8bc34a;
  background: var(--q-color-primary);
  color: rgba(33, 33, 33, 0.8);
  color: var(--q-color-textDark);
}
body.primaryDark ::selection {
  color: rgba(250, 250, 250, 0.8);
  color: var(--q-color-textLight);
}

kbd {
  font-family: "Source Code Pro";
  display: inline-block;
  padding: 0.17em 0.5em;
  margin: 0.1em 0;
  text-decoration: none;
  cursor: default;
  border-radius: 0.3em;
  background: rgba(255, 255, 255, 0.1);
  position: relative;
}
kbd + kbd {
  margin-left: 0.5em;
}

.absolute-fit {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.highlight {
  background-color: rgba(255, 255, 255, 0.1);
}

.dim {
  background-color: rgba(0, 0, 0, 0.1);
}

.hover-info {
  padding: 5px;
  margin: -5px;
  border-radius: 1em;
  background-color: rgba(255, 255, 255, 0.09);
}

.text-selectable {
  user-select: text;
}

.fullwidth-padded-md {
  padding-left: 16px;
  padding-right: 16px;
  margin-left: -16px;
  margin-right: -16px;
}

.ptn {
  font-size: 18px;
  line-height: 1.5em;
}
.small .ptn, .ptn.small {
  font-size: 14px;
  line-height: 1.5em;
}

.evaluation.p1, .evaluation.p2 {
  opacity: 0.3;
}
.evaluation.p1 {
  background-color: #cfd8dc;
  background-color: var(--q-color-player1);
}
.evaluation.p2 {
  background-color: #263238;
  background-color: var(--q-color-player2);
}

@media (min-width: 600px) {
  .q-dialog__inner--minimized > div {
    max-width: 580px;
  }
}
.q-field__control.text-negative > .q-field__prepend {
  color: #c10015;
}

.q-dialog .q-expansion-item .q-list {
  overflow-x: hidden;
  overflow-y: auto;
}

.q-field__append {
  align-self: flex-end;
}

.q-message-text-content {
  white-space: pre-wrap;
}

.q-menu {
  user-select: none;
  background: #37474f !important;
  background: var(--q-color-accent) !important;
}

.q-notifications__list--bottom {
  bottom: 58px;
}

.q-date--dark,
.q-time--dark {
  background: #263238;
  background: var(--q-color-ui);
}

.q-notification {
  transition: transform 0.3s cubic-bezier(0.25, 0.8, 0.5, 1), opacity 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}
@media (min-width: 600px) {
  .q-notification {
    max-width: 45vw;
  }
}
.q-notification.note {
  white-space: pre-line;
}
.q-notification.note .q-notification__message {
  text-overflow: ellipsis;
  overflow: hidden;
}

.q-slider__pin-value-marker-text,
.q-slider__text {
  color: rgba(33, 33, 33, 0.8);
  color: var(--q-color-textDark);
}
body.primaryDark .q-slider__pin-value-marker-text,
body.primaryDark .q-slider__text {
  color: rgba(250, 250, 250, 0.8);
  color: var(--q-color-textLight);
}

.q-expansion-item--expanded > .q-expansion-item__container > .q-item {
  color: #8bc34a;
  color: var(--q-color-primary);
}

.footer-toolbar {
  min-height: 58px;
}
.footer-toolbar .q-btn:not(.q-btn--round) {
  border-radius: 0;
}

.dimmed-btn {
  opacity: 0.2;
}
.dimmed-btn.q-fab--opened {
  opacity: 1;
}
@media (hover) {
  .dimmed-btn:hover {
    opacity: 1;
  }
}

@media (pointer: fine) {
  ::-webkit-scrollbar-corner {
    background: #263238;
    background: var(--q-color-ui);
  }

  ::-webkit-scrollbar {
    width: 14px;
    height: 14px;
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    border: 0;
    box-shadow: none;
    min-height: 40px;
    background: #37474f;
    background: var(--q-color-accent);
  }

  ::-webkit-scrollbar-track {
    background: #263238;
    background: var(--q-color-ui);
  }

  .q-drawer__content .scroll::-webkit-scrollbar-track {
    background: transparent;
  }
  .q-drawer__content .scroll::-webkit-scrollbar-thumb {
    border: 0;
    box-shadow: none;
    min-height: 40px;
    background: rgba(0, 0, 0, 0.12);
  }
  .body--dark .q-drawer__content .scroll::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, 0.28);
  }

  .q-menu.scroll::-webkit-scrollbar-track {
    background: #37474f;
    background: var(--q-color-accent);
  }
  .q-menu.scroll::-webkit-scrollbar-thumb {
    background: #263238;
    background: var(--q-color-ui);
  }
}